import { useAuth0 } from "@auth0/auth0-react";
import Icon from "@ant-design/icons";
import { Redirect, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getConfig } from "../config";
import styles from "./register.module.css";
import { useSizes } from "../hooks/useSizes";
import { Checkbox } from "antd";
import { Button } from "reactstrap";

const Register = () => {
  var currentTime = new Date();
  const { id } = useParams();
  // const { isAuthenticated, loginWithRedirect } = useAuth0();

  let urlhref = window.location.href;
  let url = new URL(urlhref);
  let referral = url.searchParams.get("ref_code") ?? id;
  const { height } = useSizes();
  const [status, setStatus] = useState(false);
  const [accept, setAccept] = useState(false);
  const base_login_url = process.env.REACT_APP_AUTH_URI;

  const auth_google = `/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_API_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${referral}&scope=aws.cognito.signin.user.admin email openid phone profile`;
  const auth_apple = `/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${process.env.REACT_APP_API_URI}&response_type=CODE&client_id=${process.env.REACT_APP_CLIENT_ID}&state=${referral}&scope=aws.cognito.signin.user.admin email openid phone profile`;

  return (
    <div className={styles.mainContent} style={{ minHeight: height }}>
      <div className={styles.mainLeft}>
        <img
          // src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/xenea-logo-white.png"
          src={"/logo_xeneawallet.png"}
          alt="Your logo blue"
          className={styles.logoApp}
        />
        {/* <div className={styles.textOmega}>Xenea Wallet</div> */}
      </div>
      <div className={styles.mainRight}>
        <div>
          <img
            src="https://xenea-prod-public-files-bucket.s3.ap-southeast-1.amazonaws.com/omega-wallet.png"
            width="200"
            style={{ marginBottom: 15 }}
            alt="Your logo goes here"
          />

          <h1 className="title" id="title">
            {status ? "Login" : " Sign up"}
          </h1>
        </div>
        <div className={styles.content}>
          <div className={styles.signupContainer}>
            {!status && (
              <div> {referral && <div>Referral code: {referral}</div>}</div>
            )}

            <Checkbox
              className={styles.Checkbox}
              onChange={(value) => {
                setAccept(value.target.checked);
              }}
            >
              I accept{" "}
              <a href="https://xenea.app/Terms-of-Use" className="privacy-link">
                Terms and Service
              </a>{" "}
              and{" "}
              <a
                href="https://xenea.app/PrivacyPolicy.html"
                className="privacy-link"
              >
                Privacy Policy
              </a>
            </Checkbox>
          </div>
          <div>
            <div className={styles.divButton}>
              <Button
                disabled={!accept}
                onClick={() => {
                  window.location = base_login_url + auth_google;
                }}
                className={styles.btnLoginGoogle}
              >
                <svg
                  width="25px"
                  height="25px"
                  viewBox="-3 0 262 262"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  />
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  />
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  />
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EB4335"
                  />
                </svg>{" "}
                Continue with Google
              </Button>{" "}
              <Button
                disabled={!accept}
                onClick={() => {
                  window.location = base_login_url + auth_apple;
                }}
                className={styles.btnLoginApple}
              >
                <svg
                  fill="#ffffff"
                  width="25px"
                  height="25px"
                  viewBox="-52.01 0 560.035 560.035"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655" />
                </svg>{" "}
                Continue with Apple
              </Button>
            </div>
          </div>
          {/* <div
            id="btn-action-wrapper"
            class="create-acc"
            className={styles.create_acc}
            onClick={() => {
              setStatus(!status);
            }}
          >
            {status ? (
              <div>
                {" "}
                If you do not have an account yet,{" "}
                <a class="textLink">sign up</a>
              </div>
            ) : (
              <div>
                If you have an account, <a class="textLink">sign in</a> here
                here
              </div>
            )}
            <div class="btn-action"></div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="#777777"
              />
            </svg>
          </div> */}
        </div>
        <p className={styles.footerSecurity}>
          &copy; {currentTime.getFullYear()} Xenea Wallet
        </p>
      </div>
    </div>
  );
};

export default Register;
