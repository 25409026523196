import React, { useEffect } from "react";
import { Client, Spin } from "@chat3one/sdk";

const Chat3Modal = ({ token, height }) => {
  const appearance = {
    spinTitle: "SPIN TO WIN",
    // colorBackground: "#000", // theme background
    // backgroundMain: "#0D8BFF", // primary color
    // backgroundSecond: "#F91880", // secondary color
    // colorText: "#fff", // text color (Opposite of theme background),
    height: "100%",
    // fontLinks: [
    //   "https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap",
    // ],

    // Visit Google Font, and get the embed link. The system will automatically import
  };

  let MyClient = Client.create({
    token: token,
    origin: "https://sdk-staging.chat3.one",
  });

  const element = document.getElementById("wallet-container");

  const callSpin = async () => {
    let mySpin = new Spin(15, MyClient, appearance);
    try {
      await mySpin.mount(element);
      document.getElementsByTagName("iframe")[0].style.border = "none";
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    if (token) {
      callSpin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, element]);
  return (
    <div style={{ background: "#000" }}>
      <div id="wallet-container" style={{ height: height }}></div>
      <div style={{ height: 6 }}></div>
    </div>
  );
};

export default Chat3Modal;
