import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";
import "./App.less";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Register from "./views/Register";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse";
import Support from "./views/Support";
import Chat3 from "./views/Chat3";
import WifiGuest from "./views/WifiGuest";
import ComingSoon from "./views/comingSoon";
import Help from "./views/Help";
initFontAwesome();

const App = () => {
  // const { isLoading, error, loginWithRedirect } = useAuth0();

  let urlhref = window.location.href;
  let url = new URL(urlhref);
  let referral = url.searchParams.get("referral");

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        {/* <NavBar />
        <Container className="flex-grow-1 mt-5"> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/chat3" exact component={Chat3} />
          <Route path="/register" exact component={Register} />
          <Route path="/register/:id" component={Register} />

          <Route path="/Privacy-Policy" exact component={PrivacyPolicy} />
          <Route path="/Terms-of-Use" exact component={TermsOfUse} />
          <Route path="/Support" exact component={Support} />
          {/* <Route path="/profile" component={Profile} /> */}
          <Route path="/wifiGuest" component={WifiGuest} />
          <Route path="/comingSoon" component={ComingSoon} />
          <Route path="/help" component={Help} />
          <Route path="/external-api" component={ExternalApi} />
        </Switch>
        {/* </Container>
        <Footer /> */}
      </div>
    </Router>
  );
};

export default App;
